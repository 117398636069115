import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const emailServices = {
    findEmails() {
        return http.get(`${API_ROOT}/notisphere/emails`).then(resp => {
            return resp
        })
    },
    getEmail(type) {
        return http.get(`${API_ROOT}/notisphere/emails/${type}`).then(resp => {
            return resp
        })
    },
    updateEmail(payload) {
        return http.put(`${API_ROOT}/notisphere/emails/${payload.email_type}`, payload).then(resp => {
            return resp
        })
    },
    getcontent(contentType) {
        return http.get(`${API_ROOT}/notisphere/content/manage/${contentType}`).then(resp => {
            return resp
        })
    },
    getcontentManagement(contentType) {
        return http.get(`${API_ROOT}/notisphere/content/${contentType}`).then(resp => {
            return resp
        })
    },
    contentManagement(payload) {
        return http.post(`${API_ROOT}/notisphere/content/management`, payload).then(resp => {
            return resp
        })
    },
}

export default emailServices
